import Big from "big.js";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NumberFormatValues } from "react-number-format";
import { DefaultTheme, useTheme } from "styled-components";
import { CurrencyInput, SearchResultsType } from "../..";
import {
  PtBrFiltersType,
  useCurrency,
  useCurrentPortfolio,
  useCurrentPortfolioId,
  useLanguage,
} from "../../../../store";
import { RuleType } from "../../../../store/actions";
import {
  SAdd,
  SAddButton,
  SColorBar,
  SForm,
  SGridColumn1,
  SGridColumn2,
  SGridContainer,
  SGridRow1,
  SGridRow2,
  SGridRow3,
  SGridRow4,
  SInvestInput,
  SInvestToogle,
  SPercentageInput,
  SRemove,
  SRemoveButton,
  STypographyTop,
  STypographyUnder,
} from "./styles";

interface ItemPropType {
  asset: SearchResultsType;
}

export const colorFromAssetType = (
  assetType: PtBrFiltersType | undefined,
  theme: DefaultTheme
): string => {
  switch (assetType) {
    case "ACAO":
      return theme.colors.stocks;
    case "FUNDO":
      return theme.colors.funds;
    case "EUROPA":
      return theme.colors.funds;
    case "PREVIDENCIA":
      return theme.colors.pension;
    case "RENDA_FIXA":
      return theme.colors.fixedIncome;
    case "VALORIZACAO":
      return theme.colors.valorization;
    case "DIVERSIFICACAO":
      return theme.colors.diversification;
    case "ESTABILIDADE":
      return theme.colors.stability;
    case "ANTIFRAGILIDADE":
      return theme.colors.antifragility;
    case "OUTROS":
      return theme.colors.others;
    case "BENCHMARK_PERSONALIZADO":
      return theme.colors.custom;
    default:
      return "white";
  }
};

export const SearchedAssetItem: React.FC<ItemPropType> = ({ asset }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [itemSelected, setItemSelected] = useState(false);
  const [assetValue, setAssetValue] = useState<undefined | number>();
  const [moneyType, setMoneyType] = useState(false);
  const [percentageType, setPercentageType] = useState(true);
  const [isAssetInsidePortfolio, setIsAssetInsidePortfolio] = useState(false);
  const {
    addPortfolioAsset,
    removePortfolioAsset,
    portfolioAssets,
    isAnyAssetLocked,
  } = useCurrentPortfolio();
  const [currentPortfolioId, setCurrentPortfolioId] = useCurrentPortfolioId();
  const [rule, setRule] = useState<RuleType>("RULE_EDIT_ASSET_PERCENTAGE");
  const rangePercentageRef = useRef<HTMLInputElement>(null);
  const [language] = useLanguage();

  const { currency } = useCurrency();

  const handlePercentageFocus = (): void => {
    if (rangePercentageRef?.current) {
      setTimeout(() => {
        const input = rangePercentageRef?.current as HTMLInputElement;
        const valueSize = String(input.value).length;
        input.select();
        input.selectionEnd = valueSize - 2;
        input.selectionStart = 0;
      }, 0);
    }
  };

  const formatAsPortfolioAssetKey = (
    type: string | null,
    identifier: string | null
  ): string => {
    return `${type}❤❤❤${identifier}`;
  };

  useEffect(() => {
    const portfolioAssetsKeys = Object.keys(portfolioAssets);
    const formattedAsset = formatAsPortfolioAssetKey(
      asset.assetType,
      asset.identifier
    );

    if (portfolioAssetsKeys.includes(formattedAsset)) {
      setIsAssetInsidePortfolio(true);
    } else {
      setIsAssetInsidePortfolio(false);
    }
  }, [
    asset.assetType,
    asset.identifier,
    portfolioAssets,
    setIsAssetInsidePortfolio,
  ]);

  const handleMoneyValue = (): void => {
    setRule("RULE_ADD_ASSET_INVESTMENT_VALUE");
    setMoneyType(true);
    setPercentageType(false);
  };

  const handlePercentageValue = (): void => {
    setRule("RULE_EDIT_ASSET_PERCENTAGE");
    setMoneyType(false);
    setPercentageType(true);
  };

  const handleExpand = (event: React.MouseEvent<HTMLElement>): void => {
    const target = event.target as HTMLDivElement;

    if (
      ["p", "div"].includes(target.tagName.toLowerCase()) &&
      target.inputMode !== "numeric"
    ) {
      setItemSelected((currentItemSelected) => !currentItemSelected);
      if (!isAnyAssetLocked) handleMoneyValue();
    }
  };

  const handleAssetValue = (values: NumberFormatValues): void => {
    setAssetValue(values.floatValue);
  };

  const handleAddItem = (): void => {
    const inputValue = assetValue === undefined ? null : Number(assetValue);
    const amount = moneyType ? inputValue : null;
    const percentage = percentageType ? inputValue : null;

    if (currentPortfolioId === null) {
      setCurrentPortfolioId(0);
    }
    addPortfolioAsset(rule, asset, percentage, amount);
    setAssetValue(0);
  };

  const handleRemoveItem = (): void => {
    removePortfolioAsset(
      formatAsPortfolioAssetKey(asset.assetType, asset.identifier)
    );
  };

  const handleEnterPress = (
    event: React.ChangeEvent<HTMLFormElement>
  ): void => {
    event.preventDefault();
    handleAddItem();
  };

  useEffect((): void => {
    if (isAnyAssetLocked) {
      handlePercentageValue();
    }
  }, [isAnyAssetLocked]);

  return (
    <SGridContainer>
      <SGridColumn1 $itemSelected={itemSelected}>
        <SGridRow1 onClick={handleExpand}>
          <SColorBar
            $barColor={colorFromAssetType(asset.portfolioCategory, theme)}
          />
          <STypographyTop>{asset.label}</STypographyTop>
        </SGridRow1>
        <SGridRow2 onClick={handleExpand}>
          <STypographyUnder
            $isEmpty={asset.assetType === "BENCHMARK_PERSONALIZADO"}
          >
            <>
              {asset.assetType === "ACAO" && (
                <>
                  {t("searchAsset.type")}: {asset.type || "-"} |{" "}
                  {t("searchAsset.stockExchange")}: {asset.stockExchange || "-"}{" "}
                  | {t("searchAsset.status")}: {asset.situation || "-"}
                </>
              )}
              {(asset.assetType === "FI" ||
                asset.assetType === "FII" ||
                asset.assetType === "FIDC") && (
                <>
                  {t("searchAsset.CNPJ")}: {asset.cnpj || "-"} |{" "}
                  {t("searchAsset.status")}: {asset.situation || "-"} |{" "}
                  {t("searchAsset.manager")}: {asset.managementCompany || "-"}
                </>
              )}
              {asset.assetType === "RENDA_FIXA" && (
                <>
                  {t("searchAsset.type")}: {asset.type || "-"} |{" "}
                  {t("searchAsset.status")}: {asset.situation || "-"}
                </>
              )}
              {asset.assetType === "PREVIDENCIA" && (
                <>
                  {t("searchAsset.status")}: {asset.situation || "-"}
                </>
              )}
              {asset.assetType === "EUROPA" && (
                <>
                  {t("searchAsset.ISIN")}: {asset.isin || "-"} |{" "}
                  {t("searchAsset.status")}: {asset.situation || "-"} |{" "}
                  {t("searchAsset.manager")}: {asset.managementCompany || "-"}
                </>
              )}
              {asset.assetType === "BENCHMARK_PERSONALIZADO"}
            </>
          </STypographyUnder>
        </SGridRow2>
        {itemSelected && (
          <>
            <SForm onSubmit={handleEnterPress} style={{ width: "100%" }}>
              <SGridRow3 onClick={handleExpand}>
                {/* Botão Moeda */}
                <SInvestToogle
                  $isSelected={moneyType}
                  disabled={isAssetInsidePortfolio || isAnyAssetLocked}
                  onClick={handleMoneyValue}
                >
                  {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore */}
                  {t(`currency.${currency}.symbol`)}
                </SInvestToogle>
                {/* Botão Porcentagem */}
                <SInvestToogle
                  $isSelected={percentageType}
                  disabled={isAssetInsidePortfolio}
                  onClick={handlePercentageValue}
                >
                  %
                </SInvestToogle>
                {moneyType && (
                  <CurrencyInput
                    value={Big(assetValue || 0)
                      .round(2)
                      .toNumber()}
                    onValueChange={handleAssetValue}
                    customInput={SInvestInput}
                    disableUnderline
                    disabled={isAssetInsidePortfolio}
                    $focusOnMount
                  />
                )}

                {percentageType && (
                  <SPercentageInput
                    value={Big(assetValue || 0)
                      .round(2)
                      .toNumber()}
                    suffix=" %"
                    disabled={isAssetInsidePortfolio}
                    decimalSeparator={language === "en" ? "." : ","}
                    thousandSeparator={language === "en" ? "," : "."}
                    decimalScale={2}
                    onValueChange={handleAssetValue}
                    autoFocus
                    onFocus={handlePercentageFocus}
                    getInputRef={rangePercentageRef}
                  />
                )}
              </SGridRow3>
            </SForm>
            <SGridRow4 onClick={handleExpand}>
              {t("searchAsset.investimentAmountOptional")}
            </SGridRow4>
          </>
        )}
      </SGridColumn1>
      <SGridColumn2 $itemSelected={itemSelected}>
        {isAssetInsidePortfolio ? (
          <SRemoveButton onClick={handleRemoveItem}>
            <SRemove />
          </SRemoveButton>
        ) : (
          <SAddButton onClick={handleAddItem}>
            <SAdd />
          </SAddButton>
        )}
      </SGridColumn2>
    </SGridContainer>
  );
};
